import React, { SVGAttributes } from 'react'

const BoltIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.271 22"
      fill="#fa6648"
      {...props}
    >
      <path
        id="bolt-2"
        d="M19.94,8.983a1,1,0,0,0-.8-.4H14.966l1.1-4.919a1,1,0,0,0-.344-1A7.912,7.912,0,0,0,11.265,1a7.9,7.9,0,0,0-4.5,1.67,1,1,0,0,0-.336.532L3.9,13.384a1,1,0,0,0,.971,1.241H8.893l-.865,7.264a.966.966,0,0,0,.35.883,1.088,1.088,0,0,0,.93.193c7.48-2.236,10.658-12.657,10.79-13.1a1,1,0,0,0-.157-.883ZM10.214,20.445l.8-6.7a1,1,0,0,0-.994-1.118H6.146L8.278,4.036A5.711,5.711,0,0,1,11.265,3a5.442,5.442,0,0,1,2.706.88L12.74,9.36a1,1,0,0,0,.976,1.219h4.006c-.947,2.449-3.392,7.723-7.509,9.864Z"
        transform="translate(-3.868 -0.999)"
        fill="inherit"
      />
    </svg>
  )
}

export default BoltIcon
