import React, { SVGAttributes } from 'react'

const PiggyBankIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.977 23.928"
      fill="#aaa"
      {...props}
    >
      <path
        id="piggy-bank"
        d="M22.432,11.046A8.055,8.055,0,0,0,21,8.719V5a2,2,0,0,0-.764-1.576,1.97,1.97,0,0,0-1.681-.374A5.668,5.668,0,0,0,14.628,6H9.265A8.371,8.371,0,0,0,2.846,8.969.991.991,0,0,1,3,7,1,1,0,0,0,3,5a3,3,0,0,0-1.268,5.709A7.976,7.976,0,0,0,5,20.921V21a3.007,3.007,0,0,0,5.829,1h2.343A3.007,3.007,0,0,0,19,21v-.092a8.021,8.021,0,0,0,3.432-3.954A1.935,1.935,0,0,0,24,15V13A1.936,1.936,0,0,0,22.432,11.046ZM18,13a1,1,0,0,1,0-2A1,1,0,0,1,18,13ZM6.052,4.512C5.49-1.079,13.792-1.62,14,4H9.266A10.454,10.454,0,0,0,6.052,4.512Z"
        transform="translate(-0.023 -0.043)"
        fill="inherit"
      />
    </svg>
  )
}

export default PiggyBankIcon
