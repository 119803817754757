import React, { SVGAttributes } from 'react'

const RocketLunchIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      fill="#eaeaea"
      {...props}
    >
      <path
        id="rocket-lunch"
        d="M11.223,5.305a1.56,1.56,0,1,1-1.56-1.56A1.56,1.56,0,0,1,11.223,5.305Zm0,4.285a6.906,6.906,0,0,1-.726,2.852A4.765,4.765,0,0,1,6.23,14.98H5.605V11.547A2.2,2.2,0,0,0,3.421,9.362H-.012V8.738A4.765,4.765,0,0,1,2.525,4.475a6.906,6.906,0,0,1,2.852-.726A9.666,9.666,0,0,1,13.078,0h0a1.878,1.878,0,0,1,1.89,1.872,9.679,9.679,0,0,1-3.749,7.718ZM1.289,8.114H2.7A21.447,21.447,0,0,1,4.329,5.141a5.613,5.613,0,0,0-1.244.449,3.445,3.445,0,0,0-1.8,2.523Zm8.538,2.525a21.446,21.446,0,0,1-2.973,1.628v1.412a3.445,3.445,0,0,0,2.523-1.8A5.613,5.613,0,0,0,9.827,10.639ZM13.72,1.855a.624.624,0,0,0-.624-.607c-3.221.092-5.4,1.326-7.5,4.258A18.675,18.675,0,0,0,4.052,8.172,3.433,3.433,0,0,1,6.8,10.918,18.675,18.675,0,0,0,9.464,9.376C12.394,7.271,13.628,5.093,13.72,1.855Zm-9.91,9.3a1.882,1.882,0,0,1,0,2.648A7.206,7.206,0,0,1,.846,14.846L-.032,15l.154-.878a7.216,7.216,0,0,1,1.039-2.964,1.918,1.918,0,0,1,2.648,0Zm-.7,1.324a.624.624,0,0,0-1.065-.441,4.568,4.568,0,0,0-.495,1.378,4.583,4.583,0,0,0,1.378-.5A.62.62,0,0,0,3.109,12.483Z"
        transform="translate(0.032 0)"
        fill="inherit"
      />
    </svg>
  )
}

export default RocketLunchIcon
