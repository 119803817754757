import React, { SVGAttributes } from "react";

const GiftBoxIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.93 56.927"
      fill="#fab648"
      {...props}
    >
      <path
        id="gift-box-benefits"
        d="M48.624,11.861h-4.59A7.956,7.956,0,0,0,32.329,1.59a15.078,15.078,0,0,0-3.866,4.464A15.07,15.07,0,0,0,24.6,1.593a7.962,7.962,0,0,0-12.657,7.5,8.009,8.009,0,0,0,.946,2.773H8.3a8.311,8.311,0,0,0-8.3,8.3v1.186a4.749,4.749,0,0,0,4.744,4.744H52.182a4.749,4.749,0,0,0,4.744-4.744V20.162A8.311,8.311,0,0,0,48.624,11.861ZM35.173,5.388a3.22,3.22,0,0,1,5.119,3.029,3.259,3.259,0,0,1-1.331,2.177,10.37,10.37,0,0,1-2.415,1.264H31.072a12.853,12.853,0,0,1,4.1-6.473ZM17.889,10.537a3.22,3.22,0,0,1,3.866-5.149,12.859,12.859,0,0,1,4.1,6.473H20.384a10.207,10.207,0,0,1-2.491-1.324ZM31.6,41.853A2.175,2.175,0,0,1,33.01,38.02h6.525l2.31-6.48a2.174,2.174,0,0,1,4.075,0l2.31,6.48h6.525a2.174,2.174,0,0,1,1.4,3.838L51.01,46.049l2.128,6.48a2.175,2.175,0,0,1-3.252,2.5l-5.987-3.9-5.887,3.94a2.175,2.175,0,0,1-3.285-2.46l2.045-6.558L31.6,41.851Zm-.652,15.073H16.6a11.861,11.861,0,0,1-11.86-11.86V30.836H37.059l-.87,2.441H33.01a6.919,6.919,0,0,0-4.4,12.258l2.678,2.177L30.2,51.2a6.922,6.922,0,0,0,.75,5.728Z"
        transform="translate(0 0.001)"
        fill="inherit"
      />
    </svg>
  );
};

export default GiftBoxIcon;
