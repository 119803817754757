import React, { SVGAttributes } from 'react'

const BanBugIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12,0C5.38,0,0,5.38,0,12s5.38,12,12,12,12-5.38,12-12S18.62,0,12,0Zm11,12c0,2.85-1.1,5.45-2.89,7.4l-3.5-3.5s.09-.08,.11-.15c.07-.2,.13-.44,.18-.7,.63,.18,1.1,.75,1.1,1.44,0,.28,.22,.5,.5,.5s.5-.22,.5-.5c0-1.21-.86-2.22-2-2.45,0-.02,0-.03,0-.05,0-.58-.09-1.27-.22-2h1.72c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5h-1.93c-.16-.71-.35-1.4-.52-2h.49c1.38,0,2.5-1.12,2.5-2.5,0-.28-.22-.5-.5-.5s-.5,.22-.5,.5c0,.83-.67,1.5-1.5,1.5h-.79c-.15-.45-.26-.77-.3-.86-.59-1.3-1.92-2.14-3.39-2.14s-2.8,.84-3.39,2.14c-.02,.05-.07,.15-.18,.64l-3.89-3.89c1.96-1.79,4.55-2.89,7.4-2.89,6.07,0,11,4.93,11,11Zm-7,2c0,.37-.06,.81-.14,1.15l-6.57-6.57s.28-1.03,.28-1.03c.43-.95,1.4-1.56,2.48-1.56s2.05,.61,2.48,1.56c.14,.31,1.47,4.38,1.47,6.44ZM1,12c0-2.85,1.1-5.45,2.89-7.4l15.52,15.52c-1.96,1.79-4.55,2.89-7.4,2.89-6.07,0-11-4.93-11-11Zm13.35,6.44c-.37,.19-.77,.34-1.19,.43-.38,.08-.78,.13-1.16,.13-.1,0-.77-.04-1.16-.13-1.88-.41-3.31-1.94-3.71-3.82-.64,.17-1.12,.75-1.12,1.45,0,.28-.22,.5-.5,.5s-.5-.22-.5-.5c0-1.21,.86-2.22,2-2.45,0-.02,0-.03,0-.05,0-.53,.07-1.2,.22-2h-1.72c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h2.33c.15,0,.29,.07,.39,.19s.13,.27,.1,.42c-.21,1-.32,1.8-.32,2.4,0,1.87,1.28,3.51,3.05,3.9,.26,.06,.79,.09,.97,.1,.29,0,.61-.04,.92-.1,.33-.07,.64-.19,.94-.34,.25-.13,.55-.03,.67,.21,.13,.25,.03,.55-.21,.67Z"/>
    </svg>
  )
}

export default BanBugIcon
